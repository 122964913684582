import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Cubes, PaddingWrapper } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/doktor-kladivo/logo-doktorkladivo.png"
import Background from "../../../images/reference/doktor-kladivo/dk.png"
import Wireframe from "../../../images/reference/doktor-kladivo/wireframe1.png"
import PenguinSmith from "../../../images/vyvoj/penguin_smith.svg"
import LeftOrRightBlock from "../../../components/reference-detail/left-or-right-block"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import PenguinsTimeline from "../../../components/reference-detail/penguins-timeline"
import { TIMELINE_DK } from "../../../model/reference-timeline-dk"

class DoktorKladivo extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Nový e-shop pro Doktor Kladivo | igloonet</title>
          <meta
            name="description"
            content="Reference vytvoření nového e-shopu pro doktorkladivo.cz, specialistu na nářadí."
          />
        </Helmet>

        <ReferenceHeader
          companyName="doktor kladivo.cz"
          heading="B2B databáze, e-shop a mikro web"
          date="srpen 2017 - červenec 2021"
        />

        <ReferenceImageText
          right
          img={Background}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti Doktor Kladivo"
          link="//doktorkladivo.cz/"
        >
          <h2>Průběh spolupráce</h2>
          <p>
            Naším úkolem bylo vytvořit kompletní vizuální identitu pro novou
            značku <b>Doktor Kladivo</b>. Celý projekt teprve začínal, takže
            bylo potřeba dodat <b>grafiku</b> a nasadit ji na <b>e-shop</b>,
            vymyslet jednoduchý způsob pro import dat od dodavatelů a vše
            synchronizovat s ekonomickým systémem <b>Pohoda</b>.
          </p>
        </ReferenceImageText>

        <PaddingWrapper>
          <ReferenceImageText
            halfImgSize
            responsiveTextOrder
            img={PenguinSmith}
            alt=""
          >
            <ul>
              <li>
                Na brandovém workshopu jsme definovali nově vzniklou značku
                Doktor Kladivo a vytvořili jí <b>vizuální identitu</b>.
              </li>
              <li>
                Naše e-shopy stojí na pevných <b>datech</b>. Z provedených
                analýz jsme získali datové podklady pro tvorbu <b>person</b>,{" "}
                <b>wireframů</b> a v podstatě celého internetového obchodu.
              </li>
              <li>
                Grafika čekalo několik perných dnů. S úkolem vytvořit{" "}
                <b>grafické</b> podklady pro celou značku a e-shop si ale
                poradil skvěle.
              </li>
              <li>
                Nedočkaví ajťáci mohli s hotovou grafikou nakódovat webové{" "}
                <b>šablony</b>a nasadit celý systém. Přidali možnost{" "}
                <b>importovat data</b> od dodavatelů a všechno propojili s{" "}
                <b>Pohodou</b>– to je u našich e-shopů už standard.
              </li>
            </ul>
          </ReferenceImageText>
        </PaddingWrapper>

        <ReferenceImageText halfImgSize right img={Wireframe} alt="">
          <h2>Analýza a wireframy</h2>
          <p>
            Klient nám opatrně naznačil, že web bude mít tisíce produktů, které
            je třeba <b>správně roztřídit</b>.
          </p>
          <p>
            Nic jsme tedy nepodcenili a provedli <b>analýzy</b>, které nám
            pomohly prolomit ledy v našich dilematech a nasměrovaly nás na
            správnou cestu.
          </p>
          <Cubes
            data={[
              {
                description: "Kategorií produktů",
                number: "400+",
              },
              {
                description: "Vytvořené persony",
                number: "4",
              },
              {
                description: "Typových podstránek",
                number: "36",
              },
            ]}
          />
        </ReferenceImageText>

        <PaddingWrapper>
          <LeftOrRightBlock right>
            <h2>Design</h2>

            <p>
              V igloo máme rádi vyvážení vizuálně <b>atraktivního designu</b> s
              uživatelskou přívětivostí.
            </p>
            <p>
              Grafika musí návštěvníka zaujmout neotřelým designem, ale nemůžeme
              zapomenout na <b>UX</b> a důvody, proč vlastně na stránky přišel.
            </p>
          </LeftOrRightBlock>
        </PaddingWrapper>

        <PaddingWrapper>
          <PenguinsTimeline data={TIMELINE_DK} />
        </PaddingWrapper>

        <PaddingWrapper>
          <LeftOrRightBlock>
            <h2>Shrnutí webu</h2>
            <p>
              Práce na projektu Doktor Kladivo byla <b>radost</b>. Klient má
              velké zkušenosti s provozováním různých internetových obchodů, a
              tak přesně <b>věděl, co chce</b> a potřebuje. Odpadla tím spousta
              administrativy, a my jsme se mohli zaměřit čistě na{" "}
              <b>realizaci</b>. Při návrzích řešení jsme měli velikou volnost,
              ať už se jednalo o přípravné práce a výrobu designu nebo o
              samotnou implementaci.
            </p>
            <p>
              Naše zapojení do projektu rozhodně <b>neskončilo spuštěním</b>{" "}
              e-shopu. Průběžně vyvíjíme a <b>přidáváme</b> další funkce, např.
              pro jednodušší administraci nebo na podporu obchodní činnosti
              společnosti. Kolegové z <b>hostingového</b> oddělení chystají
              optimalizace chodu virtuálního <b>serveru</b>, na kterém e-shop
              pro Doktor Kladivo běží. Na <b>marketingu</b> jsme se pustili do
              vyladění zbožových <b>srovnávačů</b> s ohledem na četnost a
              rozmanitost produků e-shopu.
            </p>
            <p>
              V červenci 2021 jsme web předali ke správě internímu týmu e-shopu.
            </p>
          </LeftOrRightBlock>
        </PaddingWrapper>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default DoktorKladivo
